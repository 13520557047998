<template>
    <BaseSection>
        <div class="text-center">
            <img class="my-4" :src="logo" style="max-width: 200px" />
        </div>

        <div class="card mb-4">
            <div class="card-body">
                <div class="row">
                    <div class="col-6">
                        <Multiselect
                            placeholder="Select location"
                            v-model="selectedLocation"
                            :options="locations"
                            label="name"
                        />
                    </div>
                    <div class="col-6">
                        <Multiselect
                            placeholder="Select department"
                            v-model="selectedDepartment"
                            :options="departments"
                            label="name"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div v-if="jobs.length">
            <BaseJobListItem v-for="job in jobs" :job="job" :key="job.id" />
        </div>
        <div class="text-center" v-else>
            <p>No results</p>
        </div>
    </BaseSection>
</template>
<script>
import { BaseSection, BaseJobListItem } from '@/components'
import Multiselect from 'vue-multiselect'

export default {
    name: 'JobsByCompany',

    components: { BaseSection, BaseJobListItem, Multiselect },

    mounted() {
        this.fetchDepartments()
        this.fetchLocations()
        this.fetchJobs()
    },

    computed: {
        isIdNumbersOnly: (vm) => RegExp(/^[0-9]*$/).test(vm.$route.params.id),
        flattenedSkills: (vm) => vm.skills.map((obj) => obj.name),
    },

    watch: {
        skills() {
            this.fetchJobs()
        },

        selectedDepartment() {
            this.fetchJobs()
        },

        selectedLocation() {
            this.fetchJobs()
        },
    },

    data() {
        return {
            logo: '',
            jobs: [],
            skills: [],

            locations: [],
            selectedLocation: null,

            departments: [],
            selectedDepartment: null,
        }
    },

    methods: {
        updateSkills(skills) {
            this.skills = skills
        },

        fetchLocations() {
            this.$store.state.backend
                .get(`/getjobs/locations/${this.$route.params.id}`)
                .then((res) => (this.locations = res.data))
                .catch((err) => alert(err))
        },

        fetchDepartments() {
            const sortDepartments = (a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0)

            this.$store.state.backend
                .get(`/getjobs/departments/${this.$route.params.id}`)
                .then((res) => (this.departments = res.data.sort(sortDepartments)))
                .catch((err) => alert(err))
        },

        fetchJobs() {
            const self = this

            const getByCompanyId = {
                url: `/getjobs/byCompany/${this.$route.params.id}`,
                setData(res) {
                    self.logo = res.data.company_logo
                    self.jobs = res.data.jobs.map((obj) => {
                        return {
                            ...obj,
                            company_page: obj.company_id,
                        }
                    })
                },
            }

            const getByCompanyName = {
                url:
                    `/getjobs?company=${this.$route.params.id}` +
                    `&department=${this.selectedDepartment ? this.selectedDepartment.id : ''}` +
                    `&location=${this.selectedLocation ? this.selectedLocation.id : ''}` +
                    `&keywords=${this.flattenedSkills.join(',')}`,
                setData(res) {
                    const jobs = res.data.allJobs
                    self.jobs = jobs.map((obj) => {
                        return {
                            ...obj,
                            company_page: obj.company_id,
                        }
                    })
                    if (jobs.length > 0) self.logo = jobs[0].company.logo
                },
            }

            const object = this.isIdNumbersOnly ? getByCompanyId : getByCompanyName

            this.$store.state.backend
                .get(object.url)
                .then((res) => object.setData(res))
                .catch((err) => alert(err))
        },
    },
}
</script>
